<template>
  <ul class="connected-list" :class="customClass" :style="style">
    <li
      v-for="(i, ix) of items"
      :key="ix"
      :class="{
        collapse: collapse && active_index !== ix,
      }"
    >
      <div
        class="title"
        v-if="i.title || i.subtitle"
        :class="i.titleClass"
        @click="openItem(ix)"
      >
        <h4 v-if="i.title">
          {{ i.title }}
        </h4>
        <h6 v-if="i.subtitle">{{ i.subtitle }}</h6>
      </div>
      <div class="list-body" v-html="i.body" />
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: "primary",
    },
    collapse: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemPB: {
      type: Number,
      default: 20,
    },
    itemGap: {
      type: Number,
      default: 0,
    },
    untilEnd: {
      type: Boolean,
      default: false,
    },
    itemHeight: {
      type: String,
      default: "auto",
    },
    defaultActiveIndex: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    active_index: null,
  }),
  computed: {
    style() {
      const pb = this.itemPB ? `${this.itemPB}px` : 0;
      const gap = this.itemGap ? `${this.itemGap}px` : 0;
      const iHeight = `--item-height: ${this.itemHeight}`;

      return `--item-pb: ${pb}; --item-gap: ${gap}; ${iHeight};`;
    },
    customClass() {
      const theme = `list-${this.theme}`;
      const end = this.untilEnd ? "list-until-end" : "";
      const collapse = this.collapse ? "collapsible" : "";

      return `${theme} ${end} ${collapse}`;
    },
  },
  mounted() {
    if (this.defaultActiveIndex !== null) {
      this.active_index = this.defaultActiveIndex;
    }
  },
  methods: {
    openItem(ix) {
      if (!this.collapse) return;

      if (this.active_index === ix) this.active_index = null;
      else this.active_index = ix;
    },
  },
};
</script>

<style></style>
