<template>
  <div id="faqs">
    <PageHeader fileName="header-5" withTablet withMobile>
      <h3>{{ $lang("header") }}</h3>
    </PageHeader>

    <section class="py-7">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <h2 v-html="$lang('section1.heading')" />
          </div>
          <div class="col-lg-7">
            <h6 class="fw-400 lh-3 mb-4 mt-3">
              {{ $lang("section1.paragraph1") }}
            </h6>
            <p>
              {{ $lang("section1.paragraph2") }}
            </p>

            <p>
              {{ $lang("section1.paragraph3") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <div class="section py-7 bg-dark">
      <div class="container">
        <div class="form-row">
          <div
            class="col-lg-3 col-md-6 mb-3 mb-lg-0"
            v-for="(f, i) of $lang('faq_sections')"
            :key="i"
          >
            <a
              class="card card-primary card-button text-center justify-content-center"
              :href="`#${f.title}`"
            >
              <div class="card-body" style="margin-top: unset">
                <b>{{ f.title }}</b>
              </div>
            </a>
          </div>
        </div>

        <div
          class="row mt-5"
          v-for="(f, i) of $lang('faq_sections')"
          :key="i"
          :id="f.title"
        >
          <div class="col-lg-3">
            <h4 class="text-primary mt-2">{{ f.title }}</h4>
          </div>
          <div class="col-lg-9 pt-3">
            <ConnectedList
              collapse
              :items="f.items"
              :itemPB="0"
              :itemGap="50"
            />
          </div>
        </div>
      </div>
    </div>

    <ContactFormSection fileName="footer-5" theme="primary">
      {{ $lang("contact_form.location") }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import ConnectedList from "@/components/ConnectedList.vue";

export default {
  components: {
    PageHeader,
    ContactFormSection,
    ConnectedList,
  },
};
</script>

<style></style>
